import { Injectable } from "@angular/core"
import { Subject } from "rxjs"
import { Ingredient } from "../shared/ingredient.model"

@Injectable({providedIn: 'root'})

export class ShoppingListService{
   ingredientsChangeEvent = new Subject<Ingredient[]>()
   startedEditing = new Subject<number>()

   private ingredients: Ingredient[] = [
      new Ingredient('Apples',5),
      new Ingredient('Tomatos',10),
      new Ingredient('Sugar',3)
   ]

   getIngredients(){
      return this.ingredients.slice()
   }

   getIngredient(index: number){
      return this.ingredients[index]
   }

   addIngredient(ingredient: Ingredient){
      this.ingredients.push(ingredient)
      this.ingredientsChangeEvent.next(this.ingredients.slice())
   }

   addIngredientsFromRecipe(ingredients: Ingredient[]){
      // for(let ingredient of ingredients){
      //    this.ingredients.push(new Ingredient(ingredient.name, ingredient.amount))
      // }
      this.ingredients.push(...ingredients)
      this.ingredientsChangeEvent.next(this.ingredients.slice())
   }

   updateIngredient(index: number, updatedIngredient: Ingredient){
      this.ingredients[index] = updatedIngredient
      this.ingredientsChangeEvent.next(this.ingredients.slice())
   }

   deleteIngredient(index: number){
      this.ingredients.splice(index, 1)
      this.ingredientsChangeEvent.next(this.ingredients.slice())
   }

}